import {autoRegister, resolve} from "../container";
import type {ButtonInteractData, LinkInteractData, NavigationInteractData, TrackingListener} from "./listener";
import {NativeGoogleTagManager} from "./gtm/nativeGtm";

@autoRegister()
export class Tracking {

    private listeners: TrackingListener[] = [];

    public registerUnnamedListener(listener: TrackingListener): void {
        this.listeners.push(listener);
    }

    public textlinkInteract(data: LinkInteractData): void {
        this.listeners.forEach(listener => listener.textlinkInteract(data));
    }

    public buttonInteract(data: ButtonInteractData): void {
        this.listeners.forEach(listener => listener.buttonInteract(data));
    }

    public teaserInteract(data: LinkInteractData): void {
        this.listeners.forEach(listener => listener.teaserInteract(data));
    }

    public navigationInteract(data: NavigationInteractData): void {
        this.listeners.forEach(listener => listener.navigationInteract(data));
    }
}

export class EopTracking extends HTMLElement {

    public constructor(
        private nativeGoogleTagManager: NativeGoogleTagManager = resolve(NativeGoogleTagManager)
    ) {
        super();

        this.nativeGoogleTagManager.init();
    }
}

customElements.define("eop-tracking", EopTracking);