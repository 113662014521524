import {resolve} from "../../../container";
import {HorizontalOverflow, HorizontalOverflowInstaller} from "../horizontalOverflow";
import {prepareEvents} from "../../../common/utils/events";
import {html, type PropertyValues, type TemplateResult} from "lit";
import {customElement, property, query} from "lit/decorators.js";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";
import PLUS_SIGN from "../../../../../svgpictograms/plus.svg";
import {fresh} from "../../../common/lifetime";
import {PopoverAnchor} from "../popover/popoverAnchor";

export class EopHotspots extends HTMLElement {
    private horizontalOverflow: HorizontalOverflow;

    public constructor(private horizontalOverflowInstaller: HorizontalOverflowInstaller = resolve(HorizontalOverflowInstaller)) {
        super();
    }

    public connectedCallback(): void {
        const scrollContentSelector = this.getAttribute("scroll-content-selector");
        const contentElement = (scrollContentSelector ? this.querySelector(scrollContentSelector) : this) ?? this;
        this.horizontalOverflow = this.horizontalOverflowInstaller.installOn(this, contentElement);
    }

    public disconnectedCallback(): void {
        this.horizontalOverflow.uninstall();
    }
}

customElements.define("eop-hotspots", EopHotspots);

@customElement("eop-hotspot-with-popover")
export class EopHotspotWithPopover extends PopoverAnchor {

    @query(".indicator-link")
    public indicatorLink: HTMLAnchorElement;
    @property({attribute: "label"})
    public label: string;
    @property({attribute: "label-pos"})
    public labelPos: string;

    public render(): TemplateResult {
        return html`
            <span class="${this.labelPos} hotspot-label" data-tracking-label=${this.label || "hotspot-item"}>
                ${this.label}
            </span>
            <button class="indicator-link" data-tracking-label=${this.label || "hotspot-item"}>
                <span class="indicator-plus">
                    ${unsafeSVG(PLUS_SIGN)}
                </span>
            </button>
        `;
    }

    protected getAnchorElement(): HTMLElement {
        return this.indicatorLink;
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);

        const lifetime = fresh(this, "firstUpdated");
        this.popoverElement.enforceCloseButton = true;
        prepareEvents(this)
            .boundTo(lifetime)
            .on("click", () => {
                this.setPopoverPosition();
                this.popoverElement.open();
            });
    }
}