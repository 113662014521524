import {ManagingResources} from "../common/lifetime";
import {autoRegister, resolve} from "../container";
import {prepareEvents} from "../common/utils/events";
import {GLOBAL} from "../common/globals";
import {Tracking} from "./tracking";
import {Nav} from "../common/nav";
import {
    type ButtonInteractData,
    type LinkInteractData,
    type LinkTrackingData,
    type NavigationInteractData,
    NavigationInteractionLocation,
    NavigationInteractionType,
    TrackableEventType,
    type TrackableMouseEvent
} from "./listener";
import {TrackingContext} from "./trackingContext";
import {type Trackable} from "./trackable";

@autoRegister()
export class ClickTracking {

    public constructor(
        private nav: Nav = resolve(Nav),
        private tracking: Tracking = resolve(Tracking)
    ) {
    }

    public handleTextlinkInteract(event: Event, element: Element, targetUrl?: string): void {
        const context = new TrackingContext(element);
        if (!context) {
            return;
        }

        this.markAsTracked(event);
        this.tracking.textlinkInteract({
            label: context.getEventLabel(),
            contentId: TrackingContext.makeContentId(event),
            linkData: this.linkData(targetUrl)
        });
    }

    public handleNavigationInteract(data: NavigationInteractData): void {
        this.tracking.navigationInteract(data);
    }

    public handleBodyClick(event: TrackableMouseEvent): void {
        if (event.hasBeenTracked) {
            return;
        }
        const context = TrackingContext.fromEvent(event);
        if (!context) {
            return;
        }
        const trackable = Array.from(event.composedPath())
            .filter(element => element instanceof Element)
            .filter(element => (element as any).trackingOptions)
            .first();
        if (trackable) {
            const options = (trackable as any as Trackable).trackingOptions();
            const contentId = TrackingContext.makeContentId(event);
            return this.trackEventByName(options.name, {...options.data, contentId});
        }
        return this.trackEvent(event, context);
    }

    private trackEvent(event: TrackableMouseEvent, context: TrackingContext): void {
        const contentId = TrackingContext.makeContentId(event);
        const targetUrl = context.clickedElement.getAttribute("href") ?? "";
        const linkData = this.linkData(targetUrl);
        const label = context.getEventLabel();

        switch (this.getEventName(event, context)) {
            case TrackableEventType.BUTTON:
                this.tracking.buttonInteract({label, contentId, linkData});
                break;
            case TrackableEventType.TEASER:
                this.tracking.teaserInteract({label, contentId, linkData});
                break;
            case TrackableEventType.NAVIGATION:
                this.tracking.navigationInteract({
                    label,
                    location: this.locationOf(event),
                    type: NavigationInteractionType.CLICK,
                    contentId,
                    linkData
                });
                break;
            default:
                this.tracking.textlinkInteract({label, contentId, linkData});
                break;
        }
    }

    private trackEventByName(name: TrackableEventType, data: LinkInteractData | ButtonInteractData | NavigationInteractData): void {
        switch (name) {
            case TrackableEventType.BUTTON:
                this.tracking.buttonInteract(data as ButtonInteractData);
                break;
            case TrackableEventType.NAVIGATION:
                this.tracking.navigationInteract(data as NavigationInteractData);
                break;
            default:
                this.tracking.textlinkInteract(data as LinkInteractData);
                break;
        }
    }

    private locationOf(event: Event): NavigationInteractionLocation {
        const eventPath: string[] = Array.from(event.composedPath())
            .filter(element => element instanceof Element)
            .map(element => element.tagName);
        if (eventPath.includes("HEADER")) {
            return NavigationInteractionLocation.HEADER;
        }
        if (eventPath.includes("FOOTER")) {
            return NavigationInteractionLocation.FOOTER;
        }
        return NavigationInteractionLocation.BODY;
    }

    private markAsTracked(event: Event): void {
        Object.defineProperty(event, "hasBeenTracked", {value: true, writable: true});
    }

    private linkData(targetUrl?: string): LinkTrackingData | undefined {
        if (!targetUrl) {
            return undefined;
        }
        return {
            targetUrl: this.nav.absoluteHrefFrom(targetUrl),
            isExternal: !this.nav.isInternalLink(targetUrl)
        };
    }

    private getEventName(event: TrackableMouseEvent, context: TrackingContext): TrackableEventType {
        const eventElementName = context.eventElement?.getAttribute("data-eventelement") ?? "";
        if (eventElementName === "button") {
            return TrackableEventType.BUTTON;
        }
        if (this.isTeaser(eventElementName)) {
            return TrackableEventType.TEASER;
        }
        const composedPath: string[] = Array.from(event.composedPath())
            .filter(element => element instanceof Element)
            .map(element => element.tagName);

        if (composedPath.includes("HEADER")
            || composedPath.includes("FOOTER")
            || composedPath.includes("EOP-CHAPTER-NAVIGATION")
            || composedPath.includes("EOP-CONTENT-NAVIGATION")
        ) {
            return TrackableEventType.NAVIGATION;
        }

        return TrackableEventType.TEXTLINK;
    }

    private isTeaser(eventElement: string): boolean {
        const teaserEventElements = [
            "imageteaser",
            "prio0teaser",
            "prio1teaser",
            "prio2teaser",
            "prio3teaser",
            "productteaser",
            "feedpreview",
            "feedslider",
            "feedslideroverlay",
            "pagepreview",
            "pagepreviewslider",
            "feedrelated",
            "social-wall",
            "feedsearch",
            "dashboard-tile",
            "side-teaser",
            "image-tile",
            "teaser-sequence-tile-image",
            "teaser-sequence-tile-text",
            "alles-im-blick"
        ];
        return teaserEventElements.includes(eventElement);
    }
}

export class EopClickTracking extends ManagingResources(HTMLElement) {

    public constructor(private clickTrackingListener: ClickTracking = resolve(ClickTracking)) {
        super();
    }

    public connectedCallback(): void {
        prepareEvents(GLOBAL.bodyElement())
            .boundTo(this)
            .on("click", ev => this.clickTrackingListener.handleBodyClick(ev as TrackableMouseEvent));
    }
}

customElements.define("eop-click-tracking", EopClickTracking);