import {Resolution} from "../../../common/resolution";
import {IMAGE_BREAKPOINTS} from "../../../common/resolutionConstants";
import {Cookieflag} from "../../../common/cookieflag";
import {isPresent} from "../../../common/utils/basics";
import {resolve} from "../../../container";
import {OUT_OF_VIEW_ATTR} from "./imageUtils";
import {customElement, property, query, state} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {ManagingResources} from "../../../common/lifetime";
import {UnLitElement} from "../../../common/elements";
import {PlainImageAdjuster} from "./plainImageCommons";
import {eopCustomEvent} from "../../../common/utils/events";

@customElement("eop-plain-artdirected-image")
export class EopPlainArtDirectedImage extends ManagingResources(UnLitElement) {

    @property({attribute: "src"})
    public src: string;
    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: "reverse-src-order"})
    public reverseSrcOrder: boolean = false;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;
    @property({attribute: "show-lightbox", type: Boolean})
    private showLightbox: boolean = false;

    @state()
    protected srcCurrent: string;

    @query("img")
    private imageElement: HTMLImageElement;

    private imageAdjuster: PlainImageAdjuster;

    public constructor(
        private cookieflag: Cookieflag = resolve(Cookieflag),
        private resolution: Resolution = resolve(Resolution)
    ) {
        super();
        this.imageAdjuster = new PlainImageAdjuster(() => this.imageElement, this);
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.srcCurrent = this.src ?? "";

        if (this.cookieflag.isSetFor("enbw-disable-responsive-image")) {
            return;  // used for layout tests
        }

        this.updateImage(this.resolution.getBreakpoint());
        this.resolution.onWindowResize(breakpoint => {
            this.updateImage(breakpoint);
            this.imageAdjuster.adjust(this.srcCurrent);
        }, this);
    }

    public render(): TemplateResult {
        return html`
            <div class="plain-image">
                <div class="plain-image-container">
                    <img src=${this.outOfView ? "" : this.srcCurrent}
                         alt=${this.alt ?? ""}
                         loading="lazy"
                         @load=${{handleEvent: () => this.handleImageLoaded(), once: false}}
                         class="plain-image"/>
                    ${this.renderLightboxIcon()}
                </div>
            </div>
        `;
    }

    private renderLightboxIcon(): TemplateResult | null {
        if (this.showLightbox) {
            return html`
                <div class="lightbox-image-preview-icon"></div>`;
        }
        return null;
    }

    private handleImageLoaded(): void {
        this.imageAdjuster.adjust(this.srcCurrent);
        this.dispatchEvent(eopCustomEvent("imageLoaded"));
    }

    private updateImage(newBreakpoint: number): void {
        let breakpointSource: string | null;
        if (this.reverseSrcOrder) {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => newBreakpoint < breakpoint.min)
                .map(breakpoint => this.getAttribute("src-" + breakpoint.min))
                .filter(isPresent)
                .first();
        } else {
            breakpointSource = Object.keys(IMAGE_BREAKPOINTS)
                .map(key => IMAGE_BREAKPOINTS[key])
                .filter(breakpoint => newBreakpoint >= breakpoint.min)
                .map(breakpoint => this.getAttribute("src-" + breakpoint.min))
                .filter(isPresent)
                .last();
        }

        this.srcCurrent = breakpointSource ?? this.src;
    }
}