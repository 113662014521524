import {OUT_OF_VIEW_ATTR} from "./imageUtils";
import {customElement, property, query, state} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {PlainImageAdjuster} from "./plainImageCommons";
import {eopCustomEvent} from "../../../common/utils/events";

@customElement("eop-svg-image-with-js-animation")
export class EopSvgImageWithJsAnimation extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;
    @property({attribute: "show-lightbox", type: Boolean})
    private showLightbox: boolean = false;

    @query("object")
    private objectElement: HTMLObjectElement;

    private imageAdjuster: PlainImageAdjuster;

    public constructor() {
        super();
        this.imageAdjuster = new PlainImageAdjuster(() => this.objectElement, this);
    }

    public render(): TemplateResult {
        return html`
            <div class="plain-image">
                <div class="plain-image-container">
                    <object type="image/svg+xml"
                            alt=${this.alt}
                            data=${this.isSrcReady() ? this.srcCurrent : ""}
                            @load=${{handleEvent: () => this.handleImageLoaded(), once: false}}
                            ?baseplate=${this.enableBaseplate}>
                     </object>
                    ${this.renderLightboxIcon()}
                </div>
            </div>
        `;
    }

    private renderLightboxIcon(): TemplateResult | null {
        if (this.showLightbox) {
            return html`
                <div class="lightbox-image-preview-icon"></div>`;
        }
        return null;
    }

    private handleImageLoaded(): void {
        this.imageAdjuster.adjust(this.srcCurrent);
        this.dispatchEvent(eopCustomEvent("imageLoaded"));
    }
}