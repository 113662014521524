import {html, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {OUT_OF_VIEW_ATTR} from "./imageUtils";

@customElement("eop-svg-image-special-format")
export class EopSvgImageSpecialFormat extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    private alt: string;
    @property({attribute: "show-lightbox", type: Boolean})
    private showLightbox: boolean = false;

    public render(): TemplateResult {
        return html`
            <div class="svg-image-container">
                <img src=${this.isSrcReady() ? this.srcCurrent : ""} 
                     alt=${this.alt ?? ""} 
                     loading="lazy" 
                     class="svg-image" 
                     ?baseplate=${this.enableBaseplate}/>
                ${this.renderLightboxIcon()}
            </div>`;
    }

    private renderLightboxIcon(): TemplateResult | null {
        if (this.showLightbox) {
            return html`
                <div class="lightbox-image-preview-icon"></div>`;
        }
        return null;
    }
}