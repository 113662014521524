export type TrackableMouseEvent = MouseEvent & {
    hasBeenTracked: boolean;
}

export type GenericTrackingData = {
    label: string;
    contentId: string;
}

export type LinkTrackingData = {
    targetUrl: string;
    isExternal: boolean;
}

export type LinkInteractData = GenericTrackingData & {
    linkData?: LinkTrackingData;
}

export type ButtonInteractData = LinkInteractData;

export type TeaserInteractData = LinkInteractData;

export enum TrackableEventType {
    TEXTLINK = "textlink",
    BUTTON = "button",
    TEASER = "teaser",
    NAVIGATION = "navigation"
}

export enum NavigationInteractionType {
    CLICK = "click", OPEN = "open", CLOSE = "close"
}

export enum NavigationInteractionLocation {
    BODY = "body", HEADER = "header", FOOTER = "footer"
}

export type NavigationInteractData = LinkInteractData & {
    location: NavigationInteractionLocation;
    type: NavigationInteractionType;
};

export interface TrackingListener {
    textlinkInteract: (data: LinkInteractData) => void;
    buttonInteract: (data: ButtonInteractData) => void;
    teaserInteract: (data: LinkInteractData) => void;
    navigationInteract: (data: NavigationInteractData) => void;
}