import {OUT_OF_VIEW_ATTR} from "./imageUtils";
import {customElement, property, query} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {PlainImageAdjuster} from "./plainImageCommons";
import {eopCustomEvent} from "../../../common/utils/events";

@customElement("eop-plain-image")
export class EopPlainImage extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    private alt: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;
    @property({attribute: "show-lightbox", type: Boolean})
    private showLightbox: boolean = false;

    @query("img")
    private imageElement: HTMLImageElement;

    private imageAdjuster: PlainImageAdjuster;

    public constructor() {
        super();
        this.imageAdjuster = new PlainImageAdjuster(() => this.imageElement, this);
    }

    public render(): TemplateResult {
        return html`
            <div class="plain-image">
                <div class="plain-image-container">
                    <img src=${this.isSrcReady() ? this.srcCurrent : ""}
                         alt=${this.alt ?? ""}
                         loading="lazy"
                         @load=${{handleEvent: () => this.handleImageLoaded(), once: false}}
                         ?baseplate=${this.enableBaseplate}/>
                    ${this.renderLightboxIcon()}
                </div>
            </div>
        `;
    }

    private renderLightboxIcon(): TemplateResult | null {
        if (this.showLightbox) {
            return html`
                <div class="lightbox-image-preview-icon"></div>`;
        }
        return null;
    }

    private handleImageLoaded(): void {
        this.imageAdjuster.adjust(this.srcCurrent);
        this.dispatchEvent(eopCustomEvent("imageLoaded"));
    }
}