import {html, type TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {OUT_OF_VIEW_ATTR} from "./imageUtils";

@customElement("eop-svg-image-with-js-animation-special-format")
export class EopSvgImageWithJsAnimationSpecialFormat extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;
    @property({attribute: "show-lightbox", type: Boolean})
    private showLightbox: boolean = false;

    public constructor(
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
    }

    public render(): TemplateResult {
        return html`
            <div class="svg-image-container">
                <object type="image/svg+xml"
                        class="svg-image"
                        alt=${this.alt}
                        data=${this.isSrcReady() ? this.srcCurrent : ""}
                        ?baseplate=${this.enableBaseplate}>
                </object>
                ${this.renderLightboxIcon()}
            </div>`;
    }

    private renderLightboxIcon(): TemplateResult | null {
        if (this.showLightbox) {
            return html`
                <div class="lightbox-image-preview-icon"></div>`;
        }
        return null;
    }
}