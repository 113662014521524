import type {ButtonInteractData, LinkInteractData, NavigationInteractData, TeaserInteractData, TrackingListener} from "./listener";
import {Tracking} from "./tracking";
import {resolve} from "../container";

export class DebugTrackingServiceListener implements TrackingListener {

    public textlinkInteract(data: LinkInteractData): void {
        console.log("debug tracking textlink interact:", data);
    }

    public buttonInteract(data: ButtonInteractData): void {
        console.log("debug tracking button interact:", data);
    }

    public teaserInteract(data: TeaserInteractData): void {
        console.log("debug tracking teaser interact:", data);
    }

    public navigationInteract(data: NavigationInteractData): void {
        console.log("debug tracking navigation interact:", data);
    }
}

export class EopDebugTracking extends HTMLElement {

    public constructor(private tracking: Tracking = resolve(Tracking)) {
        super();
    }

    public connectedCallback(): void {
        this.tracking.registerUnnamedListener(new DebugTrackingServiceListener());
    }
}

customElements.define("eop-debug-tracking", EopDebugTracking);