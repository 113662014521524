import {MultiScrollCarousel} from "./multiScrollCarousel";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import {customElement, property, query, queryAll, queryAssignedElements, state} from "lit/decorators.js";
import Styles from "./multiCarousel.lit.scss";
import {resolve} from "../../container";
import {Resolution} from "../../common/resolution";
import {AutoInitializing} from "../../common/elements";
import {HorizontalSwipeHandler} from "../../common/utils/events";
import {AlignedSlotsFactory} from "./alignedSlots";
import {Dictionary} from "./dictionary";

const INDICATOR_ACTIVE_CLASS = "carousel-indicator-active";


@customElement("eop-multi-carousel")
export class EopMultiCarousel extends AutoInitializing(LitElement) {

    public static readonly styles = Styles;

    @queryAssignedElements({slot: "slider-content"})
    private sliderContents: HTMLElement[];
    @queryAll(".carousel-indicator")
    private indicators: NodeListOf<HTMLElement>;
    @query(".next-button")
    private nextButton: HTMLElement;
    @query(".prev-button")
    private prevButton: HTMLElement;
    @property({attribute: "max-elements-per-slide"})
    private maxElementsPerSlide: number;
    @property({attribute: "align-element-height", type: Boolean})
    private alignElementHeight: boolean;
    @state()
    private scrollable: boolean;
    private multiScrollCarousel: MultiScrollCarousel;
    private dictionary: Dictionary;

    public constructor(
        private resolution: Resolution = resolve(Resolution),
        private alignedSlotsFactory: AlignedSlotsFactory = resolve(AlignedSlotsFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.dictionary = Dictionary.of(this);

        this.resolution.onWindowResize(() => {
            this.updateIndicatorElements(this.multiScrollCarousel.getIndicatorIndices());
            this.multiScrollCarousel.updateActiveIndices();
            this.updateControls();
        });

        new HorizontalSwipeHandler([this])
            .onSwipeLeft((e) => this.animateRightScroll())
            .onSwipeRight((e) => this.animateLeftScroll())
            .activate();
    }

    public render(): TemplateResult {
        return html`
            <div class="multi-carousel-container">
                <div class="multi-carousel-elements">
                    <slot name="slider-content"></slot>
                </div>
            </div>
            ${this.renderIndicators()}
            ${this.renderControls()}
        `;
    }

    private renderControls(): TemplateResult | null {
        this.updateControls();
        return html`
            <div class="uni-carousel-controls">
                <button type="button"
                        class="uni-carousel-control next-button"
                        data-tracking-label="next"
                        aria-label=${this.dictionary.translate("MSG_NEXT_SLIDE")}
                        @click=${this.scrollToRight}
                >
                </button>
                <button type="button"
                        class="uni-carousel-control prev-button"
                        data-tracking-label="prev"
                        aria-label=${this.dictionary.translate("MSG_PREVIOUS_SLIDE")}
                        @click=${this.scrollToLeft}
                >
                </button>
            </div>
        `;
    }

    private renderIndicators(): TemplateResult | null {
        if (!this.multiScrollCarousel?.isScrollable()) {
            return null;
        }

        return html`
            <div class="uni-carousel-indicators">
                ${this.renderIndicatorButtons()}
            </div>`;
    }

    private renderIndicatorButtons(): TemplateResult[] {
        return this.multiScrollCarousel.getIndicatorIndices().map((indicator, index) => {
            const handleClick = (e: Event): void => this.scrollToIndicator(e, indicator);
            return html`
                <button type="button"
                        class="carousel-indicator"
                        data-tracking-label="carousel-indicator"
                        aria-label=${this.dictionary.translate("MSG_GO_TO_SLIDE", "_", {"[COUNT]": index + 1})}
                        @click=${handleClick}
                ></button>
            `;
        });
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);

        this.multiScrollCarousel = new MultiScrollCarousel(this.maxElementsPerSlide)
            .withSlideSizeFactory(() => this.elementsPerIndicator())
            .withUpdateActiveIndicesCallback(indices => this.updateIndicatorElements(indices))
            .onReframe(index => {
                this.animateScroll(index);
            });

        this.multiScrollCarousel.updateNumberOfElements(this.sliderContents.length);

        if (this.alignElementHeight) {
            this.alignedSlotsFactory.installForSingleRow(this);
        }
    }

    protected updated(_changedProperties: PropertyValues): void {
        super.updated(_changedProperties);

        this.multiScrollCarousel.updateActiveIndices();
    }

    private elementsPerIndicator(): number {
        const parentWidth = this.offsetWidth;
        const elementWidth = this.getElementWidth();
        return Math.round(parentWidth / (elementWidth > 0 ? elementWidth : 1));
    }

    private getElementWidth(): number {
        if (this.sliderContents.length > 0) {
            return this.sliderContents[0]?.offsetWidth ?? 0;
        }
        return 0;
    }

    private updateIndicatorElements(indices: number[]): void {
        this.scrollable = this.multiScrollCarousel.isScrollable();
        if (!this.scrollable) {
            this.hideIndicators();
            return;
        }

        if (this.indicators.length === 0) {
            return;
        }

        this.updateIndicators(indices);
    }

    private updateIndicators(activesIndices: number[]): void {
        const index = activesIndices.findFirst(i => this.multiScrollCarousel.isIndicatorClickable(i)) ?? 0;
        const indicatorIndices = this.multiScrollCarousel.getIndicatorIndices();
        const indicatorElementIndex = indicatorIndices.indexOf(index);
        this.hideIndicators();

        this.indicators.item(indicatorElementIndex).classList.add(INDICATOR_ACTIVE_CLASS);
        this.multiScrollCarousel.clickableIndicators()
            .forEach(i => this.indicators.item(indicatorIndices.indexOf(i)).removeAttribute("hidden"));
    }

    private hideIndicators(): void {
        this.indicators.forEach(indicator => {
            indicator.setAttribute("hidden", "");
            indicator.classList.remove(INDICATOR_ACTIVE_CLASS);
        });
    }

    private updateControls(): void {
        this.prevButton?.toggleAttribute("shown", this.multiScrollCarousel.canScrollLeft());
        this.nextButton?.toggleAttribute("shown", this.multiScrollCarousel.canScrollRight());
    }

    private scrollToRight(e: Event): void {
        e.stopPropagation();
        this.animateRightScroll();
    }

    private animateRightScroll(): void {
        this.multiScrollCarousel.scrollRight(index => this.animateScroll(index));
        this.updateControls();
    }

    private scrollToLeft(e: Event): void {
        e.stopPropagation();
        this.animateLeftScroll();
    }

    private animateLeftScroll(): void {
        this.multiScrollCarousel.scrollLeft(index => this.animateScroll(index));
        this.updateControls();
    }

    private scrollToIndicator(e: Event, index: number): void {
        e.stopPropagation();
        this.multiScrollCarousel.scrollToElement(index, i => this.animateScroll(i));
        this.updateControls();
    }

    private animateScroll(index: number): void {
        const scrollDistance = this.getScrollDistance(index);
        this.style.setProperty("--scrollDistance", scrollDistance + "px");
    }

    private getScrollDistance(index: number): number {
        return -(this.getElementWidth()) * index;
    }
}